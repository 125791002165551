import { useEffect, useState } from 'react';

import axios from 'axios';

import AppContext from './AppContext';

export default function AppContextProvider({ children }) {
    const pages = [
        { title: 'Home', url: '/' },
        { title: 'About', url: '/about' }, 
        { title: 'Concerts', url: '/concerts' },
        { title: 'Teaching', url: '/teaching'},
        { title: 'Contact', url: '/contact'}
      ];

      const [concerts, setConcerts] = useState([]);
      useEffect(() => {
        const fetchData = async () => {
                // const data = await axios.get('/api');
                const data = await axios.get('https://benfryxelldotcomapi.mail-c69.workers.dev');
                setConcerts(data.data);
            
        };
        fetchData();
      }, []);

    return (
        <AppContext.Provider value = {{ pages, concerts }}>
            { children }
        </AppContext.Provider>
    );
};