import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import App from './App';
import About from './components/About';
import Events from './components/Events';
import Teaching from './components/Teaching';
import Contact from './components/Contact';

import { ChakraProvider } from '@chakra-ui/react';

import AppContextProvider from './AppContextProvider';
import RandomPuzzle from './components/RandomPuzzle';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <AppContextProvider>
      <BrowserRouter>
      <Routes>
        <Route path = "/" element = {<App />} />
        <Route path = "/about" element = {<About />} />
        <Route path = "/concerts" element = {<Events />} />
        <Route path = "/teaching" element = {<Teaching />} />
        <Route path = "/contact" element = {<Contact />} />
        <Route path = "/randompuzzle" element = {<RandomPuzzle />} />
      </Routes>
      </BrowserRouter>
        
      </AppContextProvider>
    </ChakraProvider>
  </React.StrictMode>
);
